<template>
    <b-col md="12" sm="12" lg="12">
        <b-form-group
            label-cols-sm="4"
            :label-for="data.field_name"
        >
            <template v-slot:label>
                {{ local === 'bn' ? data.label_bn : data.label }}
            </template>
            <p v-if="(data.value && data.value === '০') || (data.value && data.value === '0')" class="text-bold" style="font-weight: 600;"> : </p>
            <p v-else-if="data.field_name.match('mobile')" class="text-bold" style="font-weight: 600;"> :
                <slot v-if="parseInt(service_id) === 1">
                    {{mobileNoSet(data.value)}}
                </slot>
                <slot v-else>
                    {{ local === 'bn' ? '০' + $n(data.value, { useGrouping: false }) : '0' + $n(data.value, { useGrouping: false }) }}
                </slot>
            </p>
            <p v-else-if="data.field_name.match('national')" class="text-bold" style="font-weight: 600;"> :
                <slot v-if="parseInt(service_id) === 1">
                    {{mobileNoSet(data.value, true)}}
                </slot>
                <slot v-else>
                    {{ local === 'bn' ? '০' + $n(data.value, { useGrouping: false }) : '0' + $n(data.value, { useGrouping: false }) }}
                </slot>
            </p>
            <p v-else-if="data.field_name.match('phone')" class="text-bold" style="font-weight: 600;"> : {{ local === 'bn' ? '০' + $n(data.value, { useGrouping: false }) : '0' + $n(data.value, { useGrouping: false }) }}</p>
            <p v-else-if="(data.value && data.field_type === 'number')" class="text-bold" style="font-weight: 600;"> : {{ $n(data.value, { useGrouping: false }) }}</p>
            <p v-else-if="data.value" class="text-bold" style="font-weight: 600;"> : {{ data.value }}</p>
            <p v-else class="text-bold" style="font-weight: 600;"> : ..................</p>
        </b-form-group>
    </b-col>
</template>
<script>
export default {
    props: ['tmpData', 'index', 'service_id'],
    components: {
    },
    data () {
        return {
            data: {
            },
            result: 0
        }
    },
    computed: {
        local: function () {
            return this.$i18n.locale
        }
    },
    methods: {
      mobileNoSet (data, nid = false) {
        const myArray = data.split(',')
        const dataVal = []
        if (myArray) {
          myArray.forEach((item, key) => {
            let dataFind = []
            if (nid) {
                dataFind = this.$n(item, { useGrouping: false })
            } else {
                dataFind = this.$i18n.locale === 'bn' ? '০' + this.$n(item, { useGrouping: false }) : '0' + this.$n(item, { useGrouping: false })
            }
            dataVal.push(dataFind)
          })
        }
        return dataVal.toString()
      }
    },
    created () {
        this.data = this.tmpData[this.index]
    }
}
</script>
